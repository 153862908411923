import React from "react"
import * as styles from "./ctaBanner.module.scss"
import LearnMoreButton from "../learnMoreButton/learnMoreButton"

const CTABanner = ({ tagline, CTA, CTADetail }) => (
  <div className={styles.banner}>
    <div className={styles.bannerContent}>
      <h3>{tagline}</h3>
      <LearnMoreButton CTA={CTA} CTADetail={CTADetail} isCTABanner={true} />
    </div>
  </div>
)

export default CTABanner
