import React, { Component } from "react"
import Layout from "../components/layout"
import HeaderBanner from "../components/common/headerBanner/headerBanner"
import CTABanner from "../components/common/ctaBanner/ctaBanner"
import * as styles from "./partners.module.scss"
import Breadcrumb from "../components/common/breadcrumb/breadcrumb"
import { importAll } from "../utils/image-utils"
import { PartnersReasonsColumns } from "../components/sections/partners/partner-reasons-columns"
// import { PartnerVideos } from "../components/sections/partners/partner-videos"
// import { Heading } from "../components/common/typography"
import { PartnersGrid } from "../components/sections/partners/partner-grid"
import { TM } from "../components/common/typography/trademark"

const PARTNERS = [
  {
    name: "Keyfactor",
    img: "keyfactor.png",
    links: [
      {
        label: "PR Announcement",
        url:
          "https://01com.com/pdf/2023/Keyfactor_01_Partnership_Press_Release.pdf",
      },
    ],
  },
  {
    name: "Thales",
    img: "thales.png",
    links: [
      {
        label: "PR Announcement",
        url:
          "https://www.01com.com/pdf/2022/Thales_01_Partnership_Press_Release.pdf",
      },
      {
        label: "Solutions",
        url:
          "https://cpl.thalesgroup.com/partners/01-communique-laboratory-inc",
      },
      {
        label: "Blog",
        url:
          "https://cpl.thalesgroup.com/blog/data-protection/building-quantum-safe-blockchain-security",
      },
    ],
  },
  {
    name: "Hitachi Solutions Create",
    img: "hitachi-logo.png",
    links: [
      {
        label: "PR Announcement",
        url:
          "https://www.hitachi-solutions-create.co.jp/solution/domobile_asp/index.html",
      },
    ],
  },
  {
    name: "CGI",
    img: "cgi-logo.png",
    additionalStyles: { width: "60%" },
    links: [
      {
        label: "PR Announcement",
        url: "https://www.01com.com/pdf/2020/Partnership-July-2020.pdf",
      },
      {
        label: "Webinar",
        url: "https://youtu.be/p3es6SPb6oA",
      },
    ],
  },
  {
    name: "PwC China",
    img: "pwc.png",
    links: [
      {
        label: "PR Announcement",
        url: "https://www.01com.com/pdf/2020/Joint-Press-Release-PWC.pdf",
      },
      {
        label: "Webinar",
        url: "https://youtu.be/eVkxYhfZWrs",
      },
      {
        label: "Whitepaper",
        url:
          "https://www.pwccn.com/en/issues/cybersecurity-and-data-privacy/rethinking-cybersecurity-in-a-quantum-world-jul2021.pdf",
      },
      {
        label: "Quantum-Safe Solution",
        url: "https://www.youtube.com/watch?v=1Ql7fmGTB4c",
      },
    ],
  },
  {
    name: "ISA Cybersecurity",
    img: "isa-logo.png",
    additionalStyles: { width: "70%" },
    links: [
      {
        label: "PR Announcement",
        url:
          "https://www.01com.com/pdf/2021/ISA-CyberSecurity-01%20Communique-partnership.pdf",
      },
    ],
  },
  {
    name: "Mirata",
    img: "mirata.png",
    additionalStyles: { width: "55%" },
    links: [
      {
        label: "PR Announcement",
        url:
          "https://www.01com.com/pdf/2021/ISA-CyberSecurity-01%20Communique-partnership.pdf",
      },
      {
        label: "Quantum-Safe Webinar",
        url: "https://vimeo.com/529739263",
      },
      {
        label: "Interview with Professor Sergey Bezzateev",
        url: "https://vimeo.com/529265672",
      },
    ],
  },
  {
    name: "ixFintech",
    img: "ixfintech.png",
    additionalStyles: { width: "70%" },
    links: [
      {
        label: "PR Announcement",
        url:
          "https://www.01com.com/pdf/2020/Quantum-safe-Bitcoin-ATM-machine.pdf",
      },
      {
        label: "Use Case",
        url: " https://www.01com.com/pdf/2021/UseCase-DAEM.pdf",
      },
    ],
  },
  {
    name: "Nexusguard",
    img: "nexusguard.jpeg",
    additionalStyles: { width: "120%" },
    links: [
      {
        label: "PR Announcement",
        url: "https://www.01com.com/pdf/2020/NexusGuard-press-release.pdf",
      },
    ],
  },
  {
    name: "Digiflynt",
    img: "digiflynt.png",
    additionalStyles: { width: "65%" },
    links: [
      {
        label: "PR Announcement",
        url:
          "https://www.digiflynt.com/digiflynt-takes-ironcap-technology-to-gcc-countries-and-the-west-african-region/",
      },
      {
        label: "Q-Day Solution",
        url: "https://www.digiflynt.com/partnerships/",
      },
    ],
  },
  {
    name: "Privacy Horizon",
    img: "phi.jpeg",
    additionalStyles: { width: "110%" },
    links: [
      {
        label: "PR Announcement",
        url:
          "https://www.01com.com/pdf/2020/Partnership-agreeemnt-with-Privacy-Horizons.pdf",
      },
    ],
  },
  {
    name: "rThreat",
    img: "rthreat.png",
    additionalStyles: { width: "60%" },
    links: [
      {
        label: "PR Announcement",
        url:
          "https://rthreat.net/2021/04/06/01-communique-signs-partnership-agreement-with-rthreat-inc/",
      },
    ],
  },

  {
    name: "PolyDigi Tech",
    img: "polydigi.jpeg",
    links: [
      {
        label: "PR Announcement",
        url: "https://polydigitech.uk/news/20210629_press/",
      },
    ],
  },
]

const importedNewsImages = importAll(
  require.context("../../assets/news", false, /\.(png|jpe?g|svg)$/)
)

const partners = PARTNERS.map(partner => {
  return {
    name: partner.name,
    image: importedNewsImages[partner.img],
    alt: `${partner.name} logo`,
    additionalStyles: partner.additionalStyles || {},
    links: partner.links,
  }
})

class Partners extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ie: false,
    }
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      let isIE = /*@cc_on!@*/ false || !!document.documentMode
      this.setState({
        ie: isIE,
      })
    }
  }

  render() {
    return (
      <Layout>
        <HeaderBanner
          title={
            <>
              Partner With IronCAP
              <TM />
            </>
          }
        />
        <div className={styles.pageContainer}>
          <Breadcrumb firstLink="/partners" firstLinkTitle="Partners" />
          <div className={styles.pageText}>
            <PartnersReasonsColumns />
          </div>
          <PartnersGrid partners={partners} />
          <CTABanner
            tagline="Step Into The Future"
            CTA="video"
            CTADetail="how video"
          />
        </div>
      </Layout>
    )
  }
}

export default Partners
