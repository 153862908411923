import React, { Component } from "react"
import { navigate } from "gatsby"
import * as styles from "./learnMoreButton.module.scss"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

class LearnMoreButton extends Component {
  constructor(props) {
    super()
    this.state = {
      howVideoIsOpen: false,
      isBrowser: false,
    }
    this.openModal = this.openModal.bind(this)
    this.routeChange = this.routeChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      this.setState({
        isBrowser: true,
      })
    }
    if (!document.getElementById("wistia_script")) {
      var wistiaScript = document.createElement("script")
      wistiaScript.id = "wistia_script"
      wistiaScript.type = "text/javascript"
      wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
      wistiaScript.async = true
      wistiaScript.popover = true
      document.body.appendChild(wistiaScript)
    }
  }

  componentWillUnmount() {
    this.handle && this.handle.remove()
  }

  openModal() {
    if (this.props.CTADetail === "how video") {
      this.setState({ howVideoIsOpen: true })
    }
  }

  routeChange() {
    if (this.props.CTADetail.includes("IronCAP X")) {
      return navigate("/ironcap-x")
    } else if (this.props.CTADetail.includes("IronCAP Toolkits")) {
      return navigate("/ironcap-toolkits")
    } else {
      return window.location.assign(this.props.CTADetail)
    }
  }

  handleClick(CTAType) {
    //TODO: is there an instance anymore where it isn't a video?
    return CTAType === "video" ? this.openModal() : this.routeChange()
  }

  render() {
    return (
      <React.Fragment>
        <button
          onClick={() => this.handleClick(this.props.CTA)}
          className={
            this.props.isCTABanner
              ? styles.learnMoreButtonCTA
              : styles.learnMoreButton
          }
        >
          {this.props.customText ? this.props.customText : "Learn More"}
        </button>
        {this.state.isBrowser && (
          <ModalVideo
            channel="youtube"
            isOpen={this.state.howVideoIsOpen}
            videoId="Eeh_r2Q3WJg"
            onClose={() => this.setState({ howVideoIsOpen: false })}
          />
        )}
      </React.Fragment>
    )
  }
}
export default LearnMoreButton
