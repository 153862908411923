import { css } from "styled-components"

const breakpoints = {
  xs: "320px",
  sm: "569px",
  md: "813px", // tablet
  lg: "1025px", // tablet large
  xl: "1250px",
}

export const mediaQuery = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `
    return accumulator
  },
  {}
)
