import styled from "styled-components"
import { mediaQuery } from "../../../media-queries"

export const Text = styled.p`
  line-height: 2;
  font-size: 19px;
  ${mediaQuery.sm`
    font-size: 19px`}
  ${mediaQuery.md`
    font-size: 17px`}
  ${mediaQuery.lg`
    font-size: 17px;`}
  ${mediaQuery.xl`
    font-size: 19px;`}
`
