import styled from "styled-components"
import { theme } from "../../../tokens"

export const OutboundLinkWrapper = styled.div`
  & > a {
    color: ${theme.colors.green};
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
  }
  & > a:hover {
    color: ${theme.colors.green};
    text-decoration: none;
  }
`
