import styled from "styled-components"
import { theme } from "../../../tokens"

export const AccentHeading = styled.h4`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  color: ${theme.colors.textGrey};
`
