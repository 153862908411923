import React from "react"
import styled from "styled-components"

import { PartnersReasonsItem } from "./partner-reasons-items"
import InnovateSVG from "../../../../assets/partners/innovate.inline.svg"
import IntegrateSVG from "../../../../assets/partners/integrate.inline.svg"
import FutureSVG from "../../../../assets/partners/future.inline.svg"
import LeaderSVG from "../../../../assets/partners/leader.inline.svg"
import { theme } from "../../../tokens"
import { Heading } from "../../common/typography"
import { TM } from "../../common/typography/trademark"

const REASONS = [
  {
    title: "Innovative Technology",
    text: (
      <>
        Combining many years of proven expertise in the field, IronCAP
        <TM />
        's crypto-scientists have created an encryption technology that's not
        only quantum-safe but also more efficient and safer than existing
        cryptography. It is built on the longest time-tested (almost 50 years)
        Goppa code-based theory recognized by the world of post-quantum
        cryptography. IronCAP
        <TM /> has been endorsed by industry experts and proven "un-hackable" in
        multiple global hackathons.
      </>
    ),
    icon: InnovateSVG,
  },
  {
    title: "Future of Cybersecurity",
    text: (
      <>
        IronCAP
        <TM />
        's patent-protected post-quantum cryptography is designed to be used in
        all kinds of vertical solutions such as digital identity, email/file
        encryption, remote access/VPN, cloud storage, 5G/IoT, blockchains,
        financial transactions, etc. IronCAP
        <TM /> lets you stay ahead of cyber threats today and in the quantum
        computing era.
      </>
    ),
    icon: FutureSVG,
  },
  {
    title: "Seamless Integration",
    text: (
      <>
        To empower vendors to transform their applications or systems seamlessly
        to quantum-safe, IronCAP
        <TM /> has developed an API that's compliant with the PKCS#11 and
        OpenPGP (RfC4880) industry standard. It is available for all major
        operating systems: Microsoft Windows, Apple macOS, Linux, Android and
        Apple iOS.
      </>
    ),
    icon: IntegrateSVG,
  },

  {
    title: "Industry Leader",
    text: (
      <>
        Utilizing the IronCAP
        <TM /> Toolkits, we have developed the industry's first quantum-safe
        email encryption software, IronCAP X<TM /> that everyone can use today
        on their classical computers. Besides setting a precedent for developing
        a practical solution using IronCAP
        <TM />
        's post-quantum cryptography, we can also help vendors to become the
        First Movers of their respective solutions for the quantum computing
        era.
      </>
    ),
    icon: LeaderSVG,
  },
]

const ParterReasonsContainer = styled.div`
  margin: 86px 0px;
`

const PartnersColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 70px;
  text-align: center;
  justify-items: center;

  @media (max-width: ${theme.breakpoints.xl}) {
    row-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`

export const PartnersReasonsColumns = () => {
  return (
    <ParterReasonsContainer>
      <Heading align="center" style={{ marginBottom: "60px" }}>
        Why Partner with IronCAP
        <TM />
      </Heading>

      <PartnersColumns>
        {REASONS.map(reason => (
          <PartnersReasonsItem
            title={reason.title}
            text={reason.text}
            IconSVG={reason.icon}
          />
        ))}
      </PartnersColumns>
    </ParterReasonsContainer>
  )
}
