import React, { Component } from "react"
import { Link } from "gatsby"
import * as styles from "./breadcrumb.module.css"
import { getStringWithTrademark } from "../../../utils/get-string-with-trademark"

class Breadcrumb extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDesktopOrTablet: true,
    }
  }

  componentDidMount() {
    this.updateViewport()
    window.addEventListener("resize", this.updateViewport)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewport)
  }

  updateViewport = () => {
    this.setState({ isDesktopOrTablet: window.innerWidth > 812 })
  }

  render() {
    const {
      firstLink,
      secondLink,
      thirdLink,
      firstLinkTitle,
      secondLinkTitle,
      thirdLinkTitle,
    } = this.props

    if (this.state.isDesktopOrTablet) {
      return (
        <div className={styles.breadcrumb}>
          <Link to="/">Home</Link>
          {secondLink ? (
            <>
              <span className={styles.breadcrumbDivider}> > </span>
              <Link to={firstLink}>
                {getStringWithTrademark(firstLinkTitle)}
              </Link>
            </>
          ) : (
            <>
              <span className={styles.breadcrumbDivider}> > </span>
              <p>{firstLinkTitle}</p>
            </>
          )}
          {secondLink ? (
            thirdLink ? (
              <>
                <span className={styles.breadcrumbDivider}> > </span>
                <Link to={secondLink}>
                  {getStringWithTrademark(secondLinkTitle)}
                </Link>
              </>
            ) : (
              <>
                <span className={styles.breadcrumbDivider}> > </span>
                <p>{getStringWithTrademark(secondLinkTitle)}</p>
              </>
            )
          ) : null}

          {thirdLink ? (
            <>
              <span className={styles.breadcrumbDivider}> > </span>
              <p> {getStringWithTrademark(thirdLinkTitle)} </p>
            </>
          ) : null}
        </div>
      )
    } else {
      return null
    }
  }
}

export default Breadcrumb
