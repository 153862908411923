import styled from "styled-components"

const getTextAlignment = props => {
  switch (props.align) {
    case "left":
      return "left"
    case "center":
      return "center"
    default:
      return "left"
  }
}
export const Heading4 = styled.h4`
  font-size: 18px;
  text-align: ${getTextAlignment};
`
