import styled from "styled-components"

const getTextAlignment = props => {
  switch (props.align) {
    case "left":
      return "left"
    case "center":
      return "center"
    default:
      return "left"
  }
}
export const Heading = styled.h2`
  font-size: 28px;
  text-align: ${getTextAlignment};
`
