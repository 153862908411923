const colors = {
  green: "#0dc47c",
  lightGrey: "#d8d8d8",
  textGrey: "#c9c9c9",
}

const breakpoints = {
  xs: "320px", // // mobileSmall
  sm: "569px", // mobileMedium
  md: "813px", // tablet
  lg: "1025px", // tablet large
  xl: "1250px",
}

export const theme = {
  colors,
  breakpoints,
}
