import React from "react"

export const PartnersReasonsItem = ({ title, text, IconSVG }) => {
  return (
    <div>
      <IconSVG width="70px" height="70px" style={{ marginBottom: "18px" }} />
      <h4 style={{ fontSize: "18px", fontWeight: "700", margin: "12px 0" }}>
        {title}
      </h4>
      <p style={{ fontSize: "15px", lineHeight: "1.7" }}>{text}</p>
    </div>
  )
}
