import ReactDOMServer from "react-dom/server"

const getSubstringRecurse = input => {
  const startBracketPosition = input.indexOf("<")
  const endBracketPosition = input.indexOf("</span>")
  const tmPosition = input.indexOf("™")
  const start = input.substring(0, startBracketPosition)
  const end = input.substring(endBracketPosition + 7) // characters of </span>
  const tm = input.substring(tmPosition, tmPosition + 1)
  const totalString = start + tm + end
  if (totalString.indexOf("</span>") === -1) {
    return totalString
  } else {
    return getSubstringRecurse(totalString)
  }
}

export const getStringWithTrademark = inputText => {
  const inputString = ReactDOMServer.renderToString(inputText)

  if (typeof inputText !== "string") {
    return getSubstringRecurse(inputString)
  } else {
    return inputText
  }
}
