import { OutboundLink } from "gatsby-plugin-google-analytics"
import React from "react"
import styled from "styled-components"
import { theme } from "../../../tokens"
import { Heading } from "../../common/typography"
import { Heading4 } from "../../common/typography/heading4"
import { OutboundLinkWrapper } from "../../common/typography/outbound-link-wrapper"
import { TM } from "../../common/typography/trademark"

const PartnersGridContainer = styled.div`
  margin: 86px 0px;
`

const StyledPartnersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  row-gap: 26px;
  column-gap: 26px;
  margin-bottom: 40px;
`

const PartnerCardContainer = styled.div`
  border: 1px solid ${theme.colors.lightGrey};
  padding: 34px;
  padding-top: 0;
  display: grid;
  grid-template-rows: 190px 40px auto;
  grid-template-areas:
    "logo"
    "name"
    "links";
`

const PartnerLogosImage = styled.img`
  grid-area: logo;
  max-height: 70px;
  align-self: center;
`

const PartnerName = styled(Heading4)`
  grid-area: name;
  align-self: center;
`

const PartnerLinkContainer = styled.div`
  grid-area: links;
  display: flex;
  flex-direction: column;
`

const PartnerLink = styled(OutboundLinkWrapper)`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

export const PartnersGrid = ({ partners }) => {
  return (
    <PartnersGridContainer>
      <Heading align="center" style={{ marginBottom: "50px" }}>
        IronCAP
        <TM /> Partners
      </Heading>
      <StyledPartnersGrid>
        {partners.map(partner => (
          <PartnerCardContainer>
            <PartnerLogosImage src={partner.image} alt={partner.alt} />
            <PartnerName>{partner.name}</PartnerName>
            <PartnerLinkContainer>
              {partner.links.map(link => (
                <PartnerLink>
                  <OutboundLink href={link.url} target="_blank">
                    {link.label}
                  </OutboundLink>
                </PartnerLink>
              ))}
            </PartnerLinkContainer>
          </PartnerCardContainer>
        ))}
      </StyledPartnersGrid>
    </PartnersGridContainer>
  )
}
