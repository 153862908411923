import React from "react"
import ProgressiveImage from "react-progressive-image-loading"
import { Helmet } from "react-helmet"
// import BackgroundImage from "gatsby-background-image"
import ReactDOMServer from "react-dom/server"
import { Location } from "@reach/router"
// import { StaticImage } from "gatsby-plugin-image"

import * as styles from "./headerBanner.module.scss"
import mainbg from "../../../../assets/header-bannerbg-main.png"
import ironcapxbg from "../../../../assets/header-bannerbg-ironcapx.png"
import ironcapapibg from "../../../../assets/header-bannerbg-ironcapapi.png"
import mainbgmin from "../../../../assets/header-bannerbg-main-min.png"
import ironcapxbgmin from "../../../../assets/header-bannerbg-ironcapx-min.png"
import ironcapapibgmin from "../../../../assets/header-bannerbg-ironcapapi-min.png"
import { getStringWithTrademark } from "../../../utils/get-string-with-trademark"
import { TM } from "../typography/trademark"

const trademarkedIronCAP = (
  <>
    IronCAP
    <TM />
  </>
)

const stringifiedTrademarkedIronCAP = getStringWithTrademark(trademarkedIronCAP)

const HeaderBanner = ({ title }) => (
  <Location>
    {({ location }) => {
      let background = location.pathname.includes("ironcap-x")
        ? ironcapxbg
        : location.pathname.includes("ironcap-toolkits")
        ? ironcapapibg
        : mainbg

      let backgroundmin = location.pathname.includes("ironcap-x")
        ? ironcapxbgmin
        : location.pathname.includes("ironcap-toolkits")
        ? ironcapapibgmin
        : mainbgmin
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {`${stringifiedTrademarkedIronCAP} 
              ${
                typeof title === "string"
                  ? title
                  : getStringWithTrademark(title)
              }`}
            </title>
            <link rel="canonical" href={location.pathname} />
          </Helmet>
          {/* <StaticImage src={background} alt="" placeholder="blurred" />
          <BackgroundImage
            className={styles.headerBanner}
            // fluid={imageData}
            src={background}
            keepStatic={true}
          /> */}
          <ProgressiveImage
            preview={backgroundmin}
            src={background}
            transitionTime={500}
            transitionFunction="ease"
            render={(src, style) => (
              <div
                className={styles.headerBanner}
                style={Object.assign(style, { backgroundImage: `url(${src})` })}
              >
                <h1>{title}</h1>
              </div>
            )}
          />
        </>
      )
    }}
  </Location>
)

export default HeaderBanner
